/* Reposition Google One Tap UI */
#credential_picker_container {
  /* position: absolute !important; */
  top: 70px !important;
  z-index: 999 !important;
}

body {
  background-color: #005e7c;
}

::selection {
  background-color: rgba(220, 94, 65, 0.5);
}
